import React from 'react';
import { FormattedMessage } from 'react-intl';

import MiniCardComponent from 'components/MiniCardComponent';
import config from 'config';
import { BookModelType } from 'models/BookModel';
import { ChapterModelType } from 'models/ChapterModel';
import { ROUTE_LAYOUT } from 'utils/constants/routes';

export const DesignCardComponent = ({
  book,
  cover,
  onboardingState
}: {
  book: BookModelType;
  cover?: ChapterModelType;
  onboardingState?: string;
}) => {
  const isDesignSelected = !!cover?.layout_key;
  const isDisabled = book.isLockedForPreview;

  if (book.isLockedForOrder || book.isLockedForPreview) {
    return (
      <MiniCardComponent
        mode="hint"
        icon="LOCK_OUTLINE"
        title={<FormattedMessage id="Design" />}
        label={
          <FormattedMessage
            id={
              book.isLockedForPreview
                ? book.editing_state === 'generating'
                  ? 'pdf generating'
                  : 'locked card preview'
                : 'locked card order'
            }
          />
        }
      />
    );
  }

  if (!isDesignSelected && !onboardingState) {
    return (
      <MiniCardComponent
        mode="new"
        icon="PEN_RULER"
        title={<FormattedMessage id="Design" />}
        label={<FormattedMessage id="design label new" />}
        button={<FormattedMessage id="Discover" />}
        linkTo={ROUTE_LAYOUT}
        disabled={isDisabled}
      />
    );
  }

  const designPreview = cover?.layout?.cardPreview;

  // const label = book.hasActiveDesignTicket ? (
  //   <FormattedMessage
  //     id={
  //       book.design_ticket_author
  //         ? 'design ticket requested by'
  //         : 'design ticket requested'
  //     }
  //     values={{
  //       name: book.design_ticket_author?.fullName
  //     }}
  //   />
  // ) : !isDesignSelected ? (
  //   <FormattedMessage id="design label select" />
  // ) : book.hasFinishedDesignTicket ? (
  //   <FormattedMessage id="use design label" />
  // ) : undefined;

  // const meta = book.hasFinishedDesignTicket ? (
  //   <>
  //     <IconComponent icon="TICK_CIRCLE" fill="GREEN" />{' '}
  //     <FormattedMessage id="design ticket finished" />
  //   </>
  // ) : undefined;

  const label = undefined;
  const meta = undefined;

  return (
    <MiniCardComponent
      icon="PEN_RULER"
      title={<FormattedMessage id="Design" />}
      label={label}
      meta={meta}
      button={!label && !meta ? <FormattedMessage id="Edit" /> : undefined}
      linkTo={ROUTE_LAYOUT}
      disabled={isDisabled}
      image={
        isDesignSelected && designPreview
          ? config.layout.baseUrl + '/' + designPreview
          : undefined
      }
    />
  );
};

export default DesignCardComponent;
