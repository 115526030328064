import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import {
  createManualTextilePreorder,
  createProfileTextilePreorder,
  deactivateProfileTextilePreorder,
  getTextilePreorder,
  getTextilePreorderProfilesList,
  Preorder,
  PreorderProfileListItem,
  reactivateProfileTextilePreorder,
  removeTextilePreorder,
  updateManualTextilePreorder,
  updateProfileTextilePreorder
} from 'api/textile_deals/fetchPreorders';

export const useTextilePreorderProfilesListQuery = (
  textileOrderId?: number
) => {
  return useQuery({
    queryKey: ['textile_preorder_profile_list', textileOrderId],
    queryFn: () => getTextilePreorderProfilesList(textileOrderId!),
    enabled: !!textileOrderId
  });
};

export const useTextilePreoderQuery = (
  preoderId?: number,
  textileOrderId?: number
) => {
  return useQuery({
    queryKey: ['textile_preorder', preoderId],
    queryFn: () => getTextilePreorder(preoderId!, textileOrderId!),
    enabled: !!preoderId && !!textileOrderId
  });
};

export const useCreateManualTexitlePreorderQuery = (
  textileOrderId?: number
) => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: (patch: { data: any; textileOrderId: number }) =>
      createManualTextilePreorder(patch.data, patch.textileOrderId),
    onMutate: async (patch) => {
      await queryClient.cancelQueries([
        'textile_preorder_profile_list',
        patch.textileOrderId
      ]);
      const previousStatePreorderList:
        | PreorderProfileListItem[]
        | undefined = queryClient.getQueryData([
        'textile_preorder_profile_list',
        patch.textileOrderId
      ]);

      const profilePreorders = previousStatePreorderList;
      const newPreorder: PreorderProfileListItem = {
        name: patch.data.name,
        preorder_state: 'open',
        virtual: true
      };
      profilePreorders?.push(newPreorder);
      queryClient.setQueryData(
        ['textile_preorder_profile_list', patch.textileOrderId],
        profilePreorders
      );

      return {
        previousStatePreorderList
      };
    },
    onError: (err, patch, context) => {
      queryClient.setQueryData(
        ['textile_preorder_profile_list', patch.textileOrderId],
        context?.previousStatePreorderList
      );
    },
    onSettled: () => {
      queryClient.invalidateQueries([
        'textile_preorder_profile_list',
        textileOrderId
      ]);
    }
  });
};

export const useCreateProfileTexitlePreorderQuery = (
  textileOrderId?: number
) => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: (patch: {
      data: any;
      textileOrderId: number;
      textileProfileId: number;
      preorder_state: 'submitted' | 'no_order';
      name: string;
    }) =>
      createProfileTextilePreorder(
        patch.data,
        patch.textileOrderId,
        patch.textileProfileId,
        patch.preorder_state
      ),
    onMutate: async (patch) => {
      await queryClient.cancelQueries([
        'textile_preorder_profile_list',
        patch.textileOrderId
      ]);
      const previousStatePreorderList:
        | PreorderProfileListItem[]
        | undefined = queryClient.getQueryData([
        'textile_preorder_profile_list',
        patch.textileOrderId
      ]);


      const profilePreorders = previousStatePreorderList;
      const newPreorderList = profilePreorders?.map((preorder) => {
        if (preorder.profile_id === patch.textileProfileId) {
          return {
            ...preorder,
            name: patch.data.name,
            textile_size_id: patch.data.textile_size_id,
            profile_id: patch.textileProfileId
          };
        }
        return preorder;
      });
      queryClient.setQueryData(
        ['textile_preorder_profile_list', patch.textileOrderId],
        newPreorderList
      );

      return {
        previousStatePreorderList
      };
    },
    onError: (err, patch, context) => {
      queryClient.setQueryData(
        ['textile_preorder_profile_list', patch.textileOrderId],
        context?.previousStatePreorderList
      );
    },
    onSettled: () => {
      queryClient.invalidateQueries([
        'textile_preorder_profile_list',
        textileOrderId
      ]);
    }
  });
};

export const useManualUpdateTexitlePreorderQuery = (
  textileOrderId?: number,
  preorderId?: number
) => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: (patch: { id: number; textileOrderId: number; data: any }) =>
      updateManualTextilePreorder(patch.id, patch.textileOrderId, patch.data),
    onMutate: async (patch) => {
      await queryClient.cancelQueries([
        'textile_preorder_profile_list',
        patch.textileOrderId
      ]);
      const previousStatePreorderList:
        | PreorderProfileListItem[]
        | undefined = queryClient.getQueryData([
        'textile_preorder_profile_list',
        patch.textileOrderId
      ]);
      const previousPreorderState:
      | Preorder
      | undefined = queryClient.getQueryData([
      'textile_preorder',
      patch.id
    ]);

      const profilePreorders = previousStatePreorderList;
      const newPreorderList = profilePreorders?.map((preorder) => {
        if (preorder.preorder_id === patch.id) {
          return {
            ...preorder,
            name: patch.data.name,
            textile_size_id: patch.data.textile_size_id
          };
        }
        return preorder;
      });
      queryClient.setQueryData(
        ['textile_preorder_profile_list', patch.textileOrderId],
        newPreorderList
      );

      const newPreorderState = {
        ...previousPreorderState,
        ...patch.data
      }

      queryClient.setQueryData(
        ['textile_preorder', patch.id],
        newPreorderState
      );

      return {
        previousStatePreorderList,
        previousPreorderState
      };
    },
    onError: (err, patch, context) => {
      queryClient.setQueryData(
        ['textile_preorder_profile_list', patch.textileOrderId],
        context?.previousStatePreorderList
      );

      queryClient.setQueryData(
        ['textile_preorder', patch.id],
        context?.previousPreorderState
      );
    },
    onSettled: (patch) => {
      queryClient.invalidateQueries([
        'textile_preorder_profile_list',
        textileOrderId
      ]);

      queryClient.invalidateQueries([
        'textile_preorder',
        preorderId
      ]);
    }
  });
};

export const useProfileUpdateTexitlePreorderQuery = (
  textileOrderId?: number,
  preorderId?: number
) => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: (patch: {
      id: number;
      textileOrderId: number;
      data: any;
      preorder_state: 'submitted' | 'no_order';
    }) =>
      updateProfileTextilePreorder(
        patch.id,
        patch.textileOrderId,
        patch.data.textile_profile_id,
        patch.data,
        patch.preorder_state
      ),
    onMutate: async (patch) => {
      await queryClient.cancelQueries([
        'textile_preorder_profile_list',
        patch.textileOrderId
      ]);

      await queryClient.cancelQueries([
        'textile_preorder',
        patch.id
      ]);
      const previousStatePreorderList:
        | PreorderProfileListItem[]
        | undefined = queryClient.getQueryData([
        'textile_preorder_profile_list',
        patch.textileOrderId
      ]);

      const previousPreorderState:
        | Preorder
        | undefined = queryClient.getQueryData([
        'textile_preorder',
        patch.id
      ]);

      const profilePreorders = previousStatePreorderList;
      const newPreorderList = profilePreorders?.map((preorder) => {
        if (preorder.preorder_id === patch.id) {
          return {
            ...preorder,
            preorder_state: patch.preorder_state,
            textile_size_id: patch.data.textile_size_id
          };
        }
        return preorder;
      });
      queryClient.setQueryData(
        ['textile_preorder_profile_list', patch.textileOrderId],
        newPreorderList
      );

      const newPreorderState = {
        ...previousPreorderState,
        ...patch.data,
        profile_preorder_state: patch.preorder_state
      }

      queryClient.setQueryData(
        ['textile_preorder', patch.id],
        newPreorderState
      );

      return {
        previousStatePreorderList,
        previousPreorderState
      };
    },
    onError: (err, patch, context) => {
      queryClient.setQueryData(
        ['textile_preorder_profile_list', patch.textileOrderId],
        context?.previousStatePreorderList
      );

      queryClient.setQueryData(
        ['textile_preorder', patch.id],
        context?.previousPreorderState
      );
    },
    onSettled: () => {
      queryClient.invalidateQueries([
        'textile_preorder_profile_list',
        textileOrderId
      ]);

      queryClient.invalidateQueries([
        'textile_preorder',
        preorderId
      ]);
    }
  });
};

export const useRemoveManualTextilePreorderQuery = (
  textileOrderId?: number
) => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: (patch: { id: number; textileOrderId: number }) =>
      removeTextilePreorder(patch.id, patch.textileOrderId),
    onMutate: async (patch) => {
      await queryClient.cancelQueries([
        'textile_preorder_profile_list',
        patch.textileOrderId
      ]);
      const previousStatePreorderList:
        | PreorderProfileListItem[]
        | undefined = queryClient.getQueryData([
        'textile_preorder_profile_list',
        patch.textileOrderId
      ]);

      const profilePreorders = previousStatePreorderList;
      const newPreorderList = profilePreorders?.filter(
        (preorder) => preorder.preorder_id !== patch.id
      );
      queryClient.setQueryData(
        ['textile_preorder_profile_list', patch.textileOrderId],
        newPreorderList
      );

      return {
        previousStatePreorderList
      };
    },
    onError: (err, patch, context) => {
      queryClient.setQueryData(
        ['textile_preorder_profile_list', patch.textileOrderId],
        context?.previousStatePreorderList
      );
    },
    onSettled: () => {
      queryClient.invalidateQueries([
        'textile_preorder_profile_list',
        textileOrderId
      ]);
    }
  });
};

export const useDeactivateProfileTextilePreorderQuery = (
  textileOrderId?: number,
  preorderId?: number
) => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: (patch: { id: number; textileOrderId: number}) =>
      deactivateProfileTextilePreorder(patch.id, patch.textileOrderId),
    onMutate: async (patch) => {
      await queryClient.cancelQueries([
        'textile_preorder_profile_list',
        patch.textileOrderId
      ]);
      await queryClient.cancelQueries(['textile_preorder', patch.id]);
      const previousStatePreorderList:
        | PreorderProfileListItem[]
        | undefined = queryClient.getQueryData([
        'textile_preorder_profile_list',
        patch.textileOrderId
      ]);

      const previousPreorder: Preorder | undefined = queryClient.getQueryData([
        'textile_preorder',
        patch.id
      ]);

      const profilePreorders = previousStatePreorderList;
      const newPreorderList = profilePreorders?.map((preorder) => {
        if (preorder.preorder_id === patch.id) {
          return {
            ...preorder,
            preorder_deactivated: true
          };
        }
        return preorder;
      });

      queryClient.setQueryData(
        ['textile_preorder_profile_list', patch.textileOrderId],
        newPreorderList
      );

      const newPreorderState = {
        ...previousPreorder,
        preorder_deactivated: false
      };

      queryClient.setQueryData(
        ['textile_preorder', patch.id],
        newPreorderState
      );

      return {
        previousStatePreorderList,
        previousPreorder
      };
    },
    onError: (err, patch, context) => {
      queryClient.setQueryData(
        ['textile_preorder_profile_list', patch.textileOrderId],
        context?.previousStatePreorderList
      );

      queryClient.setQueryData(
        ['textile_preorder', patch.id],
        context?.previousPreorder
      );
    },
    onSettled: () => {
      queryClient.invalidateQueries([
        'textile_preorder_profile_list',
        textileOrderId
      ]);

      queryClient.invalidateQueries([
        'textile_preorder',
        preorderId
      ]);
    }
  });
};

export const useReactivateProfileTextilePreorderQuery = (
  textileOrderId?: number,
  preorderId?: number
) => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: (patch: { id: number; textileOrderId: number}) =>
      reactivateProfileTextilePreorder(patch.id, patch.textileOrderId),
    onMutate: async (patch) => {
      await queryClient.cancelQueries([
        'textile_preorder_profile_list',
        patch.textileOrderId
      ]);
      await queryClient.cancelQueries(['textile_preorder', patch.id]);
      const previousStatePreorderList:
        | PreorderProfileListItem[]
        | undefined = queryClient.getQueryData([
        'textile_preorder_profile_list',
        patch.textileOrderId
      ]);

      const previousPreorder: Preorder | undefined = queryClient.getQueryData([
        'textile_preorder',
        patch.id
      ]);

      const profilePreorders = previousStatePreorderList;
      const newPreorderList = profilePreorders?.map((preorder) => {
        if (preorder.preorder_id === patch.id) {
          return {
            ...preorder,
            preorder_deactivated: false
          };
        }
        return preorder;
      });

      queryClient.setQueryData(
        ['textile_preorder_profile_list', patch.textileOrderId],
        newPreorderList
      );

      const newPreorderState = {
        ...previousPreorder,
        preorder_deactivated: false
      };

      queryClient.setQueryData(
        ['textile_preorder', patch.id],
        newPreorderState
      );

      return {
        previousStatePreorderList,
        previousPreorder
      };
    },
    onError: (err, patch, context) => {
      queryClient.setQueryData(
        ['textile_preorder_profile_list', patch.textileOrderId],
        context?.previousStatePreorderList
      );

      queryClient.setQueryData(
        ['textile_preorder', patch.id],
        context?.previousPreorder
      );
    },
    onSettled: () => {
      queryClient.invalidateQueries([
        'textile_preorder_profile_list',
        textileOrderId
      ]);

      queryClient.invalidateQueries([
        'textile_preorder',
        preorderId
      ]);
    }
  });
};